import instance from "@/axios"

/** 价格查询后台搜索 */
export const getQueryPriceByAdmin = async (params) => {
    return instance({
        url: '/dq_admin/coinSilverVersionGoods/management/newlist',
        method: 'GET',
        params: params
    })
}
/** 价格查询前台搜索 */
export const getQueryPriceByReception= async (params) => {
    return instance({
        url: '/dq_admin/coinSilverVersionGoods/getGoodsListOfSearch',
        method: 'GET',
        params: params
    })
}

/** 批量删除 */
export const queryPriceBatchDel = async (params) => {
    return instance({
        url: '/dq_admin/coinSilverVersionGoods/management/del',
        method: 'GET',
        params: params
    })
}
/** 批量确认 */
export const queryPriceBatchConfirm = async (params) => {
    return instance({
        url: '/dq_admin/coinSilverVersionGoods/handleReview',
        method: 'GET',
        params: params
    })
}

/** 图片对版 */
export const imgVersionByQueryPrice = async (params) => {
    return instance({
        url: '/dq_admin/coinSilverVersionGoods/addToDuibanRecord',
        method: 'POST',
        data: params
    })
}

/** 批量确认---预处理 */
export const spiderBatchConfirm = async (params) => {
    return instance({
        url: '/dq_admin/qianbiGoodsSplider/confirmDatas',
        method: 'GET',
        params: params
    })
}

/** 批量删除---预处理 */
export const spiderBatchDel = async (params) => {
    return instance({
        url: '/dq_admin/qianbiGoodsSplider/delById',
        method: 'GET',
        params: params
    })
}

/** 图片删除---预处理 */
export const spiderDeleteImg = async (params) => {
    return instance({
        url: '/dq_admin/qianbiGoodsSplider/deleteItemImage',
        method: 'GET',
        params: params
    })
}

/** 编辑回显 */
export const getEditorDetailData = async (params) => {
    return instance({
        url: '/dq_admin/coinSilverVersionGoods/getDetail',
        method: 'GET',
        params: params
    })
}
/** 编辑确认 */
/** 编辑回显 */
export const editorConfirmData = async (params) => {
    return instance({
        url: '/dq_admin/coinSilverVersionGoods/management/edit',
        method: 'POST',
        data: params
    })
}

/** 获取爬取的数据-昨天，今天 */
/** 获取物流信息 */
export const getDataByTodayOrBeforeYesterday = async (params) => {
    return instance({
        url: '/dq_admin/qianbiGoodsSplider/statisticsDaySourceData',
        method: 'GET',
        params: params
    })
}

/** 搜索词配置列表 */
export const getSearchWordConfigListApi = async (params) => {
    return instance({
        url: '/dq_admin/wordMatchCoin/list',
        method: 'GET',
        params: params
    })
}

/** 搜索词配置添加，编辑 */
export const postSearchWordConfigEditorOrAddApi = async (params) => {
    return instance({
        url: '/dq_admin/wordMatchCoin/addOrEdit',
        method: 'POST',
        data: params
    })
}

/** 删除搜索词 */
export const delSearchWordConfigApi = async (params) => {
    return instance({
        url: '/dq_admin/wordMatchCoin/deleteById',
        method: 'GET',
        params: {
            id: params
        }
    })
}
